import React from 'react'
import Layout from '../../components/layout'
import PageStructure from '../../components/pagestructure'
import SEO from '../../components/seo'
import { ABOUTUS } from '../../components/constant'
import IMGhistory from '../../images/new/history.jpg'
const History = () => {
  return (
    <div>
      <Layout>
        <SEO title="History" />
        <PageStructure
          title="History"
          desc="TESTIG"
          mode={ABOUTUS}
          image={IMGhistory}
        />
      </Layout>
    </div>
  )
}

export default History
